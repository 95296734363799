import React, { FC } from 'react';

export const IconTelegram: FC = () => (
  <svg
    width="24"
    height="24"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.41421"
    role="img"
    aria-label="Icon of Telegram messenger"
  >
    <path
      fill="#ddd"
      d="M18.384 22.78a1.19 1.19 0 0 0 1.107.145 1.16 1.16 0 0 0 .724-.84l3.768-18.136a.78.78 0 0 0-.26-.758.8.8 0 0 0-.797-.14L.542 11.4c-.335.124-.553.446-.542.8a.83.83 0 0 0 .593.764l5.474 1.693 2.21 6.615c.095.28.314.5.603.576s.596-.004.81-.207l3.096-2.923 5.598 4.062zm-11-8.677l1.68 5.538.373-3.507L19.61 6.947c.108-.098.123-.262.033-.377s-.253-.142-.376-.064L7.374 14.102z"
    />
  </svg>
);
