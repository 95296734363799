import React, { FC } from 'react';

export const IconTwitter: FC = () => (
  <svg width="24" height="24" role="img" aria-label="Icon of Twitter">
    <path
      fill="#ddd"
      d="M24 4.557a9.83 9.83 0 0 1-2.828.775 4.9 4.9 0 0 0 2.165-2.724c-.95.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555a4.93 4.93 0 0 0-4.797 6.045C7.728 8.088 4.1 6.128 1.67 3.15a4.93 4.93 0 0 0 1.523 6.574 4.91 4.91 0 0 1-2.23-.616 4.93 4.93 0 0 0 3.949 4.89c-.693.188-1.452.232-2.224.084a4.93 4.93 0 0 0 4.6 3.419A9.9 9.9 0 0 1 0 19.54a13.94 13.94 0 0 0 7.548 2.212c9.142 0 14.307-7.72 13.995-14.646.962-.695 1.797-1.562 2.457-2.55z"
    />
  </svg>
);
