import React, { FC } from 'react';

export const IconWhatsApp: FC = () => (
  <svg width="24" height="24" role="img" aria-label="Icon of WhatsApp messenger">
    <path
      fill="#ddd"
      d="M.057 24l1.687-6.163A11.87 11.87 0 0 1 .157 11.89C.16 5.335 5.495 0 12.05 0c3.18.001 6.167 1.24 8.413 3.488s3.48 5.236 3.48 8.414c-.003 6.557-5.338 11.892-11.893 11.892a11.9 11.9 0 0 1-5.688-1.448L.057 24zm6.597-3.807c1.676.995 3.276 1.6 5.392 1.592 5.448 0 9.886-4.434 9.9-9.885.002-5.462-4.415-9.9-9.88-9.892a9.92 9.92 0 0 0-9.9 9.884c-.001 2.225.65 3.9 1.746 5.634l-1 3.648 3.742-.98zM18.04 14.73c-.074-.124-.272-.198-.57-.347s-1.758-.868-2.03-.967-.47-.15-.67.15-.768.967-.94 1.165-.347.223-.644.074-1.255-.462-2.4-1.475c-.883-.788-1.48-1.76-1.653-2.06s-.018-.458.13-.606c.134-.133.297-.347.446-.52s.2-.296.3-.495.05-.372-.025-.52-.67-1.6-.916-2.206c-.242-.58-.487-.5-.67-.5h-.57c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.48S7.06 12.15 7.2 12.35s2.095 3.2 5.076 4.487a17.03 17.03 0 0 0 1.694.626c.712.226 1.36.194 1.872.118.57-.085 1.758-.72 2.006-1.413s.248-1.3.173-1.414z"
    />
  </svg>
);
